import { FilterAlt, FilterAltOff, Settings, Sync } from "@mui/icons-material";
import { Cached, CloudDownload, Download, MailOutline, Visibility } from "@mui/icons-material";
import { CircularProgress, createFilterOptions, DialogContent, InputAdornment, Popover } from "@mui/material";
import { Autocomplete, Avatar, Button, Chip, Grid, IconButton, Stack, TextField } from "@mui/material";
import { Tooltip, Typography } from "@mui/material";
import { ColumnDef, Row } from "@tanstack/react-table";
import { Buffer } from "buffer";
import { Currency } from "dinero.js";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import FilterAppliedBar from "src/Components/Common/FilterAppliedBar";
import { Android12Switch } from "src/Components/Common/Loader";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import CustomToast from "src/Components/CustomToast";
import LoadingGif from "src/Graphics/loading.gif";
import {
  updateEmailResponseMsgBC,
  updateIsSendingBulkMailsBC,
  updateMailFailedUsersBC,
  updateMailFailedWorkbookBC,
  updateOpenBulkMailReportDialogBC,
} from "src/slices/partnerCommunication/bulkMailsSlice";
import { DateDifference } from "src/Utils/DateUtils";
import { BalanceConfirmationStatuses } from "src/Utils/PartnerCommunication";
import uiLogger from "src/Utils/UiLogger";
import {
  BulkMailStatusResponse,
  BusinessPartnersListForBalanceConfirmation,
  BusinessPartnersListForBalanceConfirmationRes,
  EmailSendCount,
  EmailTemplate,
  ListOwnTallyCompanies,
  ListOwnTallyCompaniesRes,
  ListPDFTemplatesResponse,
  ListUserEmailTemplateResponse,
  MailThread,
  PartnerCommunicationSelected,
  S3Attachment,
  UseCaseType,
  ViewEmailThreadResponse,
} from "../../../../entity/recon-entity/ReconInterfaces";
import { Currencies } from "../../../../Utils/Common/Constants";
import { DefaultCurrency } from "../../../../Utils/MoneyUtils";
import { uiLoggerName } from "../../../../Utils/Recon/UiLogger/Constants";
import IndeterminateCheckbox from "../../../Admin/IndeterminateCheckbox";
import { LoggedInSkeleton } from "../../../Common/LoggedInSkeleton";
import MonetaryInput from "../../../Common/MonetaryInput";
import { getReconTopBarButtons } from "../../../Common/TopNavBar";
import useFetch from "../../../Common/useFetch";
import { userContext } from "../../../Contexts/userContext";
import { Dialog } from "../../../Dialog/Dialog";
import BpDashboardTable from "../../../ReactTable/BpDashboardTable";
import ListBusinessPartnerUsers from "../../PartnerMaster/ListBusinessPartnerUsers";
import BulkMailReportDialog from "../CommonLegacy/BulkMailReportDialog";
import {
  BranchInfo,
  CompanyInfo,
  DEFAULT_BRANCH_CODE,
  DEFAULT_COMPANY_ID,
  IntegratedDropDown,
  ListBranchesRes,
  ListCompaniesRes,
} from "../CommonLegacy/CommonComponents";
import MailThreadDialog from "../CommonLegacy/MailThreadDialog";
import StatusRefreshDialog from "../CommonLegacy/StatusRefreshDialog";
import CreateEmailTemplate from "../Communication/CreateEmailTemplate";
import SelectEmailTemplate from "../Communication/SelectEmailTemplate";
import SendEmailTemplate from "../Communication/SendEmailTemplate";
import { PartnerCommsContext } from "../Context/PartnerCommunicationContext";
import BalanceConfirmationFilter from "./BalanceConfirmationFilter";
import BalanceConfirmationSettings from "./BalanceConfirmationSettings";

import "../Styles/PartnerCommunication.scss";

let intervalId: ReturnType<typeof setTimeout>;

interface IBalanceConfirmationContext {
  rowsDataBalanceConfirmation: BusinessPartnersListForBalanceConfirmation[];
  storeRowsDataBalanceConfirmation: BusinessPartnersListForBalanceConfirmation[];
  setRowsDataBalanceConfirmation: StateDispatch<BusinessPartnersListForBalanceConfirmation[]>;
  setStoreRowsDataBalanceConfirmation: StateDispatch<BusinessPartnersListForBalanceConfirmation[]>;
  listAllBusinessPartnersWSR: () => Promise<void>;
}

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

export const BalanceConfirmationContext = createContext<IBalanceConfirmationContext>(null);
const filter = createFilterOptions<any>();

type ColDef<T = any> = ColumnDef<T> & {
  sticky?: "left" | "right";
};

export interface TallyClosingBalanceForAllBpResponse {
  message: string;
  closingBalanceOfAllBp: {
    businessPartnerId: number;
    closingBalance: number | string;
    closingBalanceDate: string;
  }[];
}

const getBlankData = (count = 20) => {
  const dataArray = [];
  for (let i = 0; i < count; i++) {
    dataArray.push({
      businessPartnerName: " ",
      businessPartnerId: 0,
      allCategories: [],
      category: [],
      balanceConfirmationStatus: " ",
      balanceConfirmationStatusWithReminderCount: " ",
      closingBalance: " ",
      dateOfClosingBalance: " ",
      latestBalanceConfirmedOn: null,
      dateOfLastBalanceConfirmationStatusChange: null,
    } as BusinessPartnersListForBalanceConfirmation);
  }
  return dataArray;
};

const BalanceConfirmation = () => {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  const history = useHistory();
  const [loader, setLoader] = useState<boolean>(true);
  const [selectedRow, setSelectedRow] = useState<BusinessPartnersListForBalanceConfirmation[]>([]);
  const [rowsDataBalanceConfirmation, setRowsDataBalanceConfirmation] = useState<
    BusinessPartnersListForBalanceConfirmation[]
  >(getBlankData());
  const [storeRowsDataBalanceConfirmation, setStoreRowsDataBalanceConfirmation] = useState<
    BusinessPartnersListForBalanceConfirmation[]
  >([]);
  const [openBalanceConfirmationSettings, setOpenBalanceConfirmationSettings] = useState<boolean>(false);
  const [openBalanceConfirmationFilter, setOpenBalanceConfirmationFilter] = useState<boolean>(false);

  const storeAllCategories = useRef<any[]>([]);
  const storeBalanceConfirmationData = useRef<any[]>([]);
  const [isFilterAppliedBalanceConfirmation, setIsFilterAppliedBalanceConfirmation] = useState<boolean>(false);
  const storeClosingBalanceValueFlag = useRef<boolean>(false);
  const storeClosingBalanceDateFlag = useRef<boolean>(false);
  const storeClosingBalanceRowID = useRef<number>(null);
  const [storeClosingBalanceDateValue, setStoreClosingBalanceDateValue] = useState<any>(null);
  const [statusDataReady, setStatusDataReady] = useState(false);
  const [statusDataFetching, setStatusDataFetching] = useState(false);
  const [statusButtonClicked, setStatusButtonClicked] = useState(false);
  const [reFilter, setReFilter] = useState<boolean>(false);
  const [statusText, setStatusText] = useState<string>("Never");
  const [openStatusRefreshDialog, setOpenStatusRefreshDialog] = useState(false);
  const [differenceTime, setDifferenceTime] = useState<number>(null);
  const lastInterval = useRef(null);
  // ----//

  const [showResetButton, setShowResetButton] = useState<boolean>(false);
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  const [file, setFile] = useState<any>(undefined);
  const [signature, setSignature] = useState<string>("");
  const [appliedFilters, setAppliedFilters] = useState<{ [key: string]: string }>({});
  const [bulkTallyCbDate, setBulkTallyCbDate] = useState<string>("");
  const [defaultTallyCbDate, setDefaultTallyCbDate] = useState<string>("");
  const [updatingTallyCbDate, setUpdatingTallyCbDate] = useState(false);
  const [loadingTallyCbAll, setLoadingTallyCbAll] = useState(false);
  const lastTallyInterval = useRef(null);
  const [lastSelectedTemplateID, SetLastSelectedTemplateID] = useState(0);
  const [bpName, setBpName] = useState("");
  const [openMailThread, setOpenMailThread] = useState(false);
  const [mailThreads, setMailThreads] = useState<MailThread[]>([]);
  const [s3Attachments, setS3Attachments] = useState<S3Attachment[]>([]);
  const [mailThreadSubject, setMailThreadSubject] = useState<string>("");
  const [mailLoading, setMailLoading] = useState(false);
  const openSelectMailTempOnFinish = useRef(false);

  // Email States BEGIN
  const {
    setOpenSelectEmailTemplate,
    setOpenSendEmailTemplate,
    hideSendEmailTemplate,
    setHideSendEmailTemplate,
    setEmailTemplates,
    setEmailTemplateContent,
    setEmailBusinessPartnerId,
    setEmailDisplayName,
    setFromEmailAddress,
    ResetContext,
    setPdfTemplates,
    setAllowSubjectChange,
  } = useContext(PartnerCommsContext);

  const dispatch = useDispatch();
  const {
    isSendingBulkMailsBC,
    openBulkMailReportDialogBC,
    emailResponseMsgBC,
    mailFailedUsersBC,
    mailFailedWorkbookBC,
  } = useSelector((state: any) => state.bulkMailsStatus);
  const [sendSoleOrBulkMail, setSendSoleOrBulkMail] = useState<EmailSendCount>(null);

  // Email States END

  const buttonRef = useRef(null);

  const storeAllBusinessPartnerIds = useRef<number[]>(null);
  const storeAllBusinessPartnerCheck = useRef<{ [k: number]: boolean }>(null);

  const [lastCompany, setLastCompany] = useState<ListOwnTallyCompanies | CompanyInfo>(null);
  const companyId = useRef<string>(null);

  const [lastBranch, setLastBranch] = useState<BranchInfo>(null);
  const branchCode = useRef<string>(null);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const [loadingManualCb, setLoadingManualCb] = useState(false);
  const currentRow = useRef<Row<BusinessPartnersListForBalanceConfirmation>>(null);
  const [reversingConfirmCB, setReversingConfirmCB] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  //  ListBusinessPartnerUsers
  const [openListBusinessPartnerUsers, setOpenListBusinessPartnerUsers] = useState<boolean>(false);
  const storeRowOpenListBusinessPartnerUsers = useRef<any>(null);

  // reset status
  const [downloadBalanceConfirmationReportBase64, setDownloadBalanceConfirmationReportBase64] = useState<string>(null);

  // delete email template
  const { setShowDeleteLoadingIcon, handleCloseDeleteModal } = useContext(PartnerCommsContext);

  // frequency
  const [optionsBalanceConfirmationFrequency, setOptionsBalanceConfirmationFrequency] = useState<string[]>([]);
  const [frequencyTableSettings, setFrequencyTableSettings] = useState<any>(null);

  const columnDefinitionBalanceConfirmation = useMemo(
    (): ColDef<BusinessPartnersListForBalanceConfirmation>[] => [
      {
        id: "selection",
        sticky: "left",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        header: ({ table }) => (
          <div>
            <IndeterminateCheckbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        cell: ({ row }) => (
          <div className="center_align_ver_horiz">
            <IndeterminateCheckbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
          </div>
        ),
        minSize: 35,
        maxSize: 35,
        size: 35,
      },
      {
        header: () => <div>Business Partner</div>,
        id: "Business partner name",
        sticky: "left",
        accessorFn: (row) => row.businessPartnerName?.toLowerCase(),
        cell: ({ row }) => (
          <div className="textOverflow_hidden_anchor">
            <a
              onClick={() => {
                storeRowOpenListBusinessPartnerUsers.current = row;
                setOpenListBusinessPartnerUsers(true);
              }}
            >
              {row.original.businessPartnerName}
            </a>
          </div>
        ),
        maxSize: 300,
        minSize: 180,
        size: 260,
      },
      {
        header: "Vendor Code",
        accessorFn: (row) => row.vendorCode,
        enableSorting: true,
        size: 130,
      },
      {
        header: () => (
          <div className="vertical_center_align" style={{ gap: 8 }}>
            <div className={!statusDataReady ? "noSort" : ""}>Status</div>
            {statusDataFetching && (
              <CircularProgress
                size={18}
                //  color="inherit"
                className="white-progress"
                sx={{ "svg *": { strokeWidth: 4 } }}
              />
            )}
          </div>
        ),
        id: "Status",
        accessorKey: "balanceConfirmationStatus",
        enableSorting: statusDataReady,
        maxSize: 310,
        minSize: 260,
        size: 260,
        cell: ({ row }) => (
          <>
            {row.original.balanceConfirmationStatus ? (
              <div className="vertical_center_align gap_10">
                <Button
                  onClick={() => {
                    // downloadLedger(row.original);
                    downloadEmailAttachments(row.original.businessPartnerId);
                    uiLogger(uiLoggerName.ui_DownloadLedgerStatus, companyId.current, branchCode.current);
                  }}
                  style={{
                    width: "240px",
                    overflowWrap: "break-word",
                    color:
                      row.original.balanceConfirmationStatus === "Balance confirmation requested" ||
                      row.original.balanceConfirmationStatus === "Check partner reply" ||
                      row.original.balanceConfirmationStatus === "Reminder sent" ||
                      row.original.balanceConfirmationStatus === "Configuration Underway" ||
                      row.original.balanceConfirmationStatus === "Balance confirmed" ||
                      row.original.balanceConfirmationStatus === "Recon shared with Partner"
                        ? "black"
                        : row.original.balanceConfirmationStatus
                        ? "white"
                        : "gray",
                    backgroundColor:
                      row.original.balanceConfirmationStatus === "Balance confirmation requested" ||
                      row.original.balanceConfirmationStatus === "Check partner reply" ||
                      row.original.balanceConfirmationStatus === "Reminder sent" ||
                      row.original.balanceConfirmationStatus === "Configuration Underway" ||
                      row.original.balanceConfirmationStatus === "Send Reminder"
                        ? "#dfae38"
                        : row.original.balanceConfirmationStatus === "Download ledger"
                        ? "#27b27c"
                        : row.original.balanceConfirmationStatus === "Balance confirmed" ||
                          row.original.balanceConfirmationStatus === "Recon shared with Partner"
                        ? "green"
                        : row.original.balanceConfirmationStatus === "Request balance confirmation"
                        ? "#774570"
                        : null,
                  }}
                  className="status_theme_btn"
                  disabled={
                    row.original.balanceConfirmationStatus === "Download ledger" ||
                    row.original.balanceConfirmationStatus === "Configuration Underway"
                      ? false
                      : true
                  }
                >
                  {row.original.balanceConfirmationStatus === "Reminder sent"
                    ? row.original?.balanceConfirmationStatusWithReminderCount
                    : row.original.balanceConfirmationStatus}
                </Button>
                {row.original?.downloadLedgerKey ? (
                  <Tooltip title="Download Ledger" arrow={true}>
                    <span className="event_wrapper">
                      <IconButton
                        onClick={() => {
                          downloadEmailAttachments(row.original.businessPartnerId);
                          uiLogger(uiLoggerName.ui_DownloadLedgerIcon, companyId.current, branchCode.current);
                        }}
                        disabled={!row.original?.downloadLedgerKey}
                        color="primary"
                      >
                        <CloudDownload />
                      </IconButton>
                    </span>
                  </Tooltip>
                ) : null}
              </div>
            ) : (
              <img src={LoadingGif} alt="Loading.." className="loading_gif" />
            )}
          </>
        ),
      },
      {
        header: () => (
          <div>
            Closing balance
            {(updatingTallyCbDate || loadingTallyCbAll) && (
              <CircularProgress className="ml_10" size={18} color="inherit" sx={{ "svg *": { strokeWidth: 4 } }} />
            )}
          </div>
        ),
        accessorKey: "closingBalance",
        enableSorting: statusDataReady,
        maxSize: 180,
        minSize: 180,
        size: 180,
        cell: ({ row }) => (
          <div className="textField_height">
            <MonetaryInput
              key={row.original.closingBalance}
              value={
                !isNaN(parseFloat(row.original.closingBalance as string))
                  ? parseFloat(row.original.closingBalance as string)
                  : row.original.closingBalance
              }
              // placeholder={row.original.closingBalance as string}
              setValue={(value) => {
                if (row.original.closingBalance === null && value === 0) return;

                if (value.toString() !== row.original?.closingBalance?.toString() && value.toString())
                  updateClosingBalanceForBalanceConfirmation(row, value ? value : 0);
              }}
              disabled={!row.original.balanceConfirmationStatus || actor.integration ? true : isSendingBulkMailsBC}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ "&>*": { fontSize: "13px !important", pt: "2px" } }}>
                    {Currencies.find((cur) => cur.code === row.original.currency)?.code || DefaultCurrency.INR}
                  </InputAdornment>
                ),
              }}
              sx={{
                "input::placeholder": {
                  fontSize: "14px",
                },
              }}
              currency={(row.original.currency as Currency) || "INR"}
            />
          </div>
        ),
      },
      {
        header: () => (
          <div>
            <div>Date of closing balance</div>
            {actor.integration && (
              <div className="textField_height">
                <TextField
                  key="bulkCBDate"
                  type="date"
                  size="small"
                  disabled={updatingTallyCbDate || isSendingBulkMailsBC}
                  defaultValue={bulkTallyCbDate}
                  // onChange={(_e) => setBulkTallyCbDate(_e.target.value)}
                  onBlur={(_e) => {
                    setBulkTallyCbDate(_e.target.value);
                    if (_e.target.value && bulkTallyCbDate !== _e.target.value)
                      UpdateErpClosingBalanceForAllBp(_e.target.value);
                  }}
                  onKeyUp={(_e) => updateClosingBalanceByClosingBalanceDate}
                  sx={{
                    div: { borderColor: "white !important", borderRadius: 16 },
                    input: { background: "white", p: "1px 8px", borderRadius: 16 },
                  }}
                  onClick={(_e) => _e.stopPropagation()}
                />
                {(updatingTallyCbDate || loadingTallyCbAll) && (
                  <CircularProgress className="ml_10" size={18} color="inherit" sx={{ "svg *": { strokeWidth: 4 } }} />
                )}
              </div>
            )}
          </div>
        ),
        accessorKey: "dateOfClosingBalance",
        enableSorting: statusDataReady,
        maxSize: 240,
        minSize: 200,
        size: 240,
        cell: ({ row }) => (
          <div className="textField_height">
            <TextField
              key={row.original?.dateOfClosingBalance?.toString()}
              id="outlined-basic"
              variant="outlined"
              disabled={
                (actor.integration ? loadingTallyCbAll : !row.original.balanceConfirmationStatus) ||
                isSendingBulkMailsBC
              }
              // placeholder="yyyy-mm-dd"
              type="date"
              size="small"
              // className="textField_height"
              defaultValue={
                storeClosingBalanceDateFlag.current &&
                storeClosingBalanceRowID.current === row.original.businessPartnerId
                  ? storeClosingBalanceDateValue !== null
                    ? moment(storeClosingBalanceDateValue)?.format("YYYY-MM-DD")
                    : storeClosingBalanceDateValue
                  : row.original.dateOfClosingBalance !== null
                  ? moment(row.original.dateOfClosingBalance)?.format("YYYY-MM-DD")
                  : row.original.dateOfClosingBalance
              }
              onClick={() => {
                storeClosingBalanceDateFlag.current = true;
                storeClosingBalanceRowID.current = row.original.businessPartnerId;
                setStoreClosingBalanceDateValue(row.original.dateOfClosingBalance);
              }}
              onChange={(e) => {
                setStoreClosingBalanceDateValue(e.target.value);
              }}
              onBlur={(e) => {
                if (e.target.value) {
                  if (actor.integration === true) {
                    // updateClosingBalanceByClosingBalanceDate(row, e.target.value);
                    UpdateErpClosingBalance(row, e.target.value);
                    setBulkTallyCbDate("");
                  } else {
                    updateClosingBalanceDateForBalanceConfirmation(row, e.target.value);
                  }
                }
              }}
            />
          </div>
        ),
      },
      {
        header: "Category",
        accessorFn: (row) => row.category?.[0]?.toLowerCase(),
        enableSorting: statusDataReady,
        maxSize: 200,
        minSize: 180,
        size: 200,
        cell: ({ row }) => (
          <div className="category_ui" key={row.original.businessPartnerId}>
            <Autocomplete
              openOnFocus={true}
              limitTags={1}
              value={row.original.category !== null && row.original.category?.length > 0 ? row.original.category : []}
              disabled={!statusDataReady}
              onChange={(_ev, val) => {
                let value = val as string[];
                if (value !== null && value?.length > 0) {
                  if (value[value.length - 1].includes("+Add => ")) {
                    value[value.length - 1] = value[value.length - 1].replace('+Add => "', "");
                    value[value.length - 1] = value[value.length - 1].replace('"', "");
                    const removeEmptyVal = value.filter((el) => {
                      return el !== "";
                    });
                    value = removeEmptyVal;
                  }
                }
                storeAllCategories.current = [...new Set([...storeAllCategories.current, ...value])];
                updateOwnAndBusinessPartnerMappingCategories(row, value);
              }}
              noOptionsText={"Enter a new category"}
              multiple={true}
              id="tags-outlined"
              options={storeAllCategories?.current}
              getOptionLabel={(option: any) => option}
              filterSelectedOptions={true}
              filterOptions={(_optionsFilt, params) => {
                const filtered = filter(
                  storeAllCategories?.current === null ? [] : storeAllCategories?.current,
                  params
                );
                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push(`+Add => "${params.inputValue.trim()}"`);
                }
                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  sx={{
                    "& input": {
                      px: "12px !important",
                    },
                  }}
                />
              )}
              sx={{
                "&, & div": {
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                },
                "& fieldset": {
                  borderWidth: "0.4px",
                  borderRadius: "12px",
                },
                "& .MuiChip-root": {
                  borderRadius: "12px",
                  marginTop: "4px",
                },
              }}
            />
          </div>
        ),
      },
      {
        header: "Last Status Change Date",
        accessorKey: "dateOfLastBalanceConfirmationStatusChange",
        enableSorting: true,
        size: 185,
        cell: ({ row }) => (
          <p>
            {" "}
            {row.original.dateOfLastBalanceConfirmationStatusChange !== null
              ? moment(row.original.dateOfLastBalanceConfirmationStatusChange)?.format("DD-MM-YYYY")
              : null}
          </p>
        ),
      },

      {
        header: "Last Ledger Received Date​",
        accessorKey: "latestBalanceConfirmedOn",
        enableSorting: true,
        size: 200,
        cell: ({ row }) => (
          <p>
            {" "}
            {row.original.latestBalanceConfirmedOn !== null
              ? moment(row.original.latestBalanceConfirmedOn)?.format("DD-MM-YYYY")
              : null}
          </p>
        ),
      },
      {
        header: "Actions",
        accessorKey: "businessPartnerId",
        enableSorting: false,
        size: 210,
        sticky: "right",
        cell: ({ row }) => (
          <Stack direction="row" gap={1} alignItems="center" position="relative" top={-1}>
            {/* Send Email Button */}
            <Tooltip
              title={
                statusDataFetching || updatingTallyCbDate || loadingTallyCbAll
                  ? `Please Wait while ${statusDataFetching ? "status" : "closing balance"} is Loading`
                  : "Send Email"
              }
              arrow={true}
            >
              <span className="disabled_wrapper">
                <IconButton
                  disabled={statusDataFetching || isSendingBulkMailsBC}
                  onClick={async () => {
                    const date = await LastStatusRefreshDate();
                    const dateDiff = DateDifference(moment(), moment(date), "minutes");
                    const { balanceConfirmationStatus } = row.original;

                    setEmailBusinessPartnerId(row.original.businessPartnerId as number);
                    setSendSoleOrBulkMail(EmailSendCount.Sole);
                    getListUserEmailSetting();

                    if (balanceConfirmationStatus === BalanceConfirmationStatuses.RequestBalanceConfirmation) {
                      setAllowSubjectChange(true);
                    } else setAllowSubjectChange(false);

                    if (
                      Math.abs(dateDiff) > 60 &&
                      balanceConfirmationStatus !== BalanceConfirmationStatuses.RequestBalanceConfirmation
                    ) {
                      setOpenStatusRefreshDialog(true);
                      setDifferenceTime(dateDiff);
                    } else {
                      setOpenSelectEmailTemplate(true);
                    }
                  }}
                >
                  <MailOutline sx={{ fill: "#541c4c" }} />
                </IconButton>
              </span>
            </Tooltip>
            {/* View Email Button */}
            <Tooltip title={"View Email"} arrow={true}>
              <IconButton
                disabled={isSendingBulkMailsBC}
                onClick={async () => {
                  setMailLoading(true);
                  setOpenMailThread(true);
                  setMailThreads([]);
                  setBpName(row.original.businessPartnerName);
                  const { mailThreads, s3Attachments, emailSubject } = await viewEmail(row.original.businessPartnerId);
                  setEmailBusinessPartnerId(row.original.businessPartnerId as number);
                  setMailLoading(false);
                  setMailThreads(mailThreads || []);
                  setS3Attachments(s3Attachments || []);
                  setMailThreadSubject(emailSubject || "");
                  uiLogger(uiLoggerName.ui_ViewEmailClicked, companyId.current, branchCode.current);
                }}
              >
                <Visibility sx={{ fill: "#541c4c" }} />
              </IconButton>
            </Tooltip>
            {/* Manual Toggle */}
            <Tooltip title={"Toggle"} arrow={true}>
              <Android12Switch
                disabled={isSendingBulkMailsBC}
                onChange={(_ev) => (currentRow.current = row)}
                onClick={(ev) => {
                  (ev.target as HTMLInputElement).checked ? setReversingConfirmCB(false) : setReversingConfirmCB(true);
                  handleClick(ev);
                }}
                checked={row.original.balanceConfirmationStatus === "Balance confirmed"}
              />
            </Tooltip>
            {/* View Recon */}
            <Tooltip title={"View Recon"} arrow={true}>
              <IconButton
                disabled={isSendingBulkMailsBC}
                onClick={() => {
                  const { businessPartnerId, businessPartnerName } = row.original;
                  const params = `bpId=${businessPartnerId}&bpName=${btoa(businessPartnerName)}`;
                  const uri = `/${actor.name}/recon360/Summary/Ledger?${params}`;
                  window.open(uri, "_blank")?.focus();
                }}
              >
                <Cached sx={{ fill: "#541c4c" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      },
    ],
    // eslint-disable-next-line
    [
      actor,
      history,
      statusDataReady,
      statusDataFetching,
      updatingTallyCbDate,
      bulkTallyCbDate,
      loadingTallyCbAll,
      rowsDataBalanceConfirmation,
      isSendingBulkMailsBC,
    ]
  );

  const PartnerCommunicationContextValue: IBalanceConfirmationContext = {
    rowsDataBalanceConfirmation,
    storeRowsDataBalanceConfirmation,
    setRowsDataBalanceConfirmation,
    setStoreRowsDataBalanceConfirmation,
    listAllBusinessPartnersWSR: () => listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh(),
  };

  const CheckDateSameWithLastStatusDate = async () => {
    const date = await LastStatusRefreshDate();
    const dateSame = moment().format("DD-MM-YYYY") === moment(date).format("DD-MM-YYYY");

    if (!dateSame) {
      listBusinessPartnersForBalanceConfirmation();
    }
  };

  useEffect(() => {
    // listPartnerCommunicationStatusResetMethod();
    listFrequencyTable();

    if (!actor.integration) {
      listAllBusinessPartnerIds();
      // listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh();
      // listBusinessPartnersForBalanceConfirmation();
      listUserEmailTemplateForBalanceConfirmation("");
      getListUserEmailSetting();
      pollIsCBStatusFetched();
      CheckDateSameWithLastStatusDate();
    }

    // clear All context state on component mount
    ResetContext();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (actor.branchLevelReconcilation ? lastCompany && lastBranch : lastCompany) {
      pollIsCBStatusFetched();
      PollErpClosingBalanceStatus();
    }
    // eslint-disable-next-line
  }, [actor, lastCompany, lastBranch]);

  useEffect(() => {
    if (statusDataFetching) {
      setStatusText("Syncing Now...");
      pollIsCBStatusFetched();
      const intervalsInMs = [10_000];

      intervalsInMs.forEach((interval) => {
        const intervalId = setInterval(() => {
          try {
            pollIsCBStatusFetched();
          } finally {
            // if (interval !== 30_000) clearInterval(intervalId);
          }
        }, interval);
        lastInterval.current = intervalId;
      });
    } else {
      window.clearInterval(lastInterval.current);
      if (actor.integration) {
        if (companyId.current) listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh();
      } else listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh();
    }

    return () => window.clearInterval(lastInterval.current);
    // eslint-disable-next-line
  }, [statusDataFetching, actor]);

  const listAllBusinessPartnerIds = async () => {
    await useFetch("/api/partnerCommunication/ListAllBusinessPartnerIds", "GET", {
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        storeAllBusinessPartnerCheck.current = response.data?.businessPartnerCheck;
        listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh();
        if (!actor.integration && actor.actorInfo.accountingSoftware !== "Tally") {
          setRowsDataBalanceConfirmation(getBlankData());
          setStatusDataReady(false);
        }
      },
    });
  };

  // this API is called repeatedly until errorOccurred or isFetched is true
  const pollIsCBStatusFetched = async () => {
    await useFetch("/api/partnerCommunication/isBalanceConfirmationStatusFetched", "GET", {
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: async (response) => {
        if (response.data.isFetched || response.data.errorOccurred) {
          setStatusDataFetching(false);
          setStatusButtonClicked(false);
          if (response.data.lastSyncDate) setStatusText(moment(response.data.lastSyncDate).format("DD-MM-YY hh:mmA"));
          if (openSelectMailTempOnFinish.current) setOpenSelectEmailTemplate(true);
          openSelectMailTempOnFinish.current = false;
        }
        if (!response.data.isFetched && !response.data.errorOccurred) setStatusDataFetching(true);

        if (response.data.errorOccurred) {
          toast.error(<CustomToast message={response.data.response} />);
        }
      },
    });
  };

  // this API is called before sending mail to check what was last status refresh time
  const LastStatusRefreshDate = async () => {
    return new Promise<Date>((resolve, reject) => {
      useFetch<{ lastRefreshDate?: Date }>("/api/partnerCommunication/GetLastStatusRefreshDate", "POST", {
        data: {
          templateType: UseCaseType.balanceConfirmation,
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
        thenCallBack: (res) => {
          if (res.data.lastRefreshDate) {
            resolve(res.data.lastRefreshDate);
          }
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  // this function calls the API which initiates a backend request to fetch the status(es) for each business partners
  const listBusinessPartnersForBalanceConfirmation = async () => {
    setStatusButtonClicked(true);
    await useFetch<BusinessPartnersListForBalanceConfirmationRes>(
      "/api/partnerCommunication/ListAllBusinessPartnersForBalanceConfirmation",
      "POST",
      {
        data: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
        thenCallBack: () => {
          setStatusDataFetching(true);
        },
        catchCallBack: () => {
          setStatusDataFetching(false);
          setStatusButtonClicked(false);
        },
      }
    );
  };

  const listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh = async () => {
    await useFetch<BusinessPartnersListForBalanceConfirmationRes>(
      "/api/partnerCommunication/ListAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh",
      "GET",
      {
        config: {
          params: {
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (response) => {
          // Extract the businessPartnerId values and store them in a new array
          storeAllBusinessPartnerIds.current = response.data?.businessPartnersList?.map(
            (businessPartner) => businessPartner?.businessPartnerId
          );
          storeBalanceConfirmationData.current = response.data.businessPartnersList;
          storeAllCategories.current = response.data.businessPartnersList[0]?.allCategories || [];
          setRowsDataBalanceConfirmation(response.data.businessPartnersList);
          setStoreRowsDataBalanceConfirmation(response.data.businessPartnersList);
          setLoader(false);
          setStatusDataReady(true);
          if (Object.keys(appliedFilters).length) setReFilter(true);
          if (rowsDataBalanceConfirmation && defaultTallyCbDate)
            GetErpClosingBalanceForAllBp(response.data.businessPartnersList);
        },
        catchCallBack: () => {
          setLoader(false);
        },
      }
    );
  };

  const listPDFTemplates = (createTemplateName = "") => {
    useFetch<ListPDFTemplatesResponse>("/api/partnerCommunication/ListPDFTemplates", "GET", {
      config: {
        params: {
          templateType: UseCaseType.balanceConfirmation,
        },
      },
      thenCallBack: (response) => {
        setPdfTemplates(response.data.templates || []);
        if (createTemplateName.trim() !== "") {
          setOpenSelectEmailTemplate(false);
          setOpenSendEmailTemplate(hideSendEmailTemplate ? false : true);
          setHideSendEmailTemplate(false);
        }
      },
    });
  };

  const listUserEmailTemplateForBalanceConfirmation = (createTemplateName: string) => {
    useFetch<ListUserEmailTemplateResponse>("/api/partnerCommunication/ListUserEmailTemplate", "GET", {
      config: {
        params: {
          templateType: "CB",
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        const { emailTemplates, lastEmailTemplateSelected } = response.data;
        setEmailTemplates(emailTemplates);
        listPDFTemplates();

        if (lastEmailTemplateSelected) SetLastSelectedTemplateID(lastEmailTemplateSelected);
        if (createTemplateName.trim() !== "") {
          if (response.data?.emailTemplates !== null && response.data?.emailTemplates.length > 0) {
            const filteredSelectedTemplate = response.data.emailTemplates?.filter((item: EmailTemplate) => {
              return item.templateName === createTemplateName;
            })[0];
            if (filteredSelectedTemplate) setEmailTemplateContent(filteredSelectedTemplate);
          }
          setOpenSelectEmailTemplate(false);
          setOpenSendEmailTemplate(hideSendEmailTemplate ? false : true);
          // setSelectedTemplate("");
          setHideSendEmailTemplate(false);
        }
      },
    });
  };

  const getListUserEmailSetting = async () => {
    await useFetch("/api/partnerCommunication/ListUserEmailSetting", "GET", {
      config: {
        params: {
          userId: actor.userId,
        },
      },
      thenCallBack: (response) => {
        if (
          response.data.userEmailSetting.display_name !== null &&
          response.data.userEmailSetting.display_name !== undefined &&
          response.data.userEmailSetting.display_name !== ""
        ) {
          setEmailDisplayName(response.data.userEmailSetting.display_name);
        }
        if (
          response.data.userEmailSetting.from_email !== null &&
          response.data.userEmailSetting.from_email !== undefined &&
          response.data.userEmailSetting.from_email !== ""
        ) {
          setFromEmailAddress(response.data.userEmailSetting.from_email);
        }

        if (
          response.data.userEmailSetting.signature.image !== null &&
          response.data.userEmailSetting.signature.image !== undefined &&
          response.data.userEmailSetting.signature.image !== ""
        ) {
          // setFile(response.data.userEmailSetting.signature.image);
          setFile(`data:image/*;base64,${response.data.userEmailSetting.signature.image}`);
        }
        if (
          response.data.userEmailSetting.signature.text !== null &&
          response.data.userEmailSetting.signature.text !== undefined &&
          response.data.userEmailSetting.signature.text !== ""
        ) {
          setSignature(response.data.userEmailSetting.signature.text);
        }
      },
    });
  };

  const updateOwnAndBusinessPartnerMappingCategories = async (row: any, value: any) => {
    await useFetch("/api/UpdateOwnAndBusinessPartnerMappingCategories", "POST", {
      data: {
        ownId: actor.id,
        businessPartnerId: row.original.businessPartnerId,
        category: value,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: () => {
        updateCategory(row, value);
      },
    });
  };

  const updateCategory = (row: any, value: any) => {
    storeBalanceConfirmationData.current.find(
      (v: any) => v.businessPartnerId === row.original.businessPartnerId
    ).category = value;
    // setRowsDataBalanceConfirmation(storeBalanceConfirmationData.current);
    // setStoreRowsDataBalanceConfirmation(storeBalanceConfirmationData.current);
  };

  const updateClosingBalanceForBalanceConfirmation = async (
    row: Row<BusinessPartnersListForBalanceConfirmation>,
    value: number
  ) => {
    await useFetch("/api/partnerCommunication/UpdateClosingBalanceForBalanceConfirmation", "POST", {
      data: {
        ownId: actor.id,
        businessPartnerId: row.original.businessPartnerId,
        closingBalance: value,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: () => {
        storeClosingBalanceValueFlag.current = false;
        storeClosingBalanceDateFlag.current = false;

        if (actor.actorInfo.accountingSoftware !== "Tally") {
          // listBusinessPartnersForBalanceConfirmation();
          storeBalanceConfirmationData.current?.map((item: any) => {
            if (item.businessPartnerId === row.original.businessPartnerId) {
              item.closingBalance = value;
            }
            return item;
          });
        }
      },
    });
  };

  const updateClosingBalanceDateForBalanceConfirmation = async (
    row: Row<BusinessPartnersListForBalanceConfirmation>,
    value: string
  ) => {
    await useFetch("/api/partnerCommunication/UpdateClosingBalanceDateForBalanceConfirmation", "POST", {
      data: {
        ownId: actor.id,
        businessPartnerId: row.original.businessPartnerId,
        closingBalanceDate: value,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: () => {
        storeClosingBalanceValueFlag.current = false;
        storeClosingBalanceDateFlag.current = false;

        // setLoader(true);
        if (actor.actorInfo.accountingSoftware !== "Tally") {
          // listBusinessPartnersForBalanceConfirmation();
          storeBalanceConfirmationData.current?.map((item: any) => {
            if (item.businessPartnerId === row.original.businessPartnerId) {
              item.dateOfClosingBalance = value;
            }
            return item;
          });
        }
      },
    });
  };

  const downloadEmailAttachments = (bpId: number) => {
    useFetch<{ s3Attachment: S3Attachment[] }>("/api/partnerCommunication/DownloadEmailAttachments", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: bpId,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        templateType: UseCaseType.balanceConfirmation,
      },
      thenCallBack: (_res) => {
        const { s3Attachment } = _res.data;
        if (s3Attachment?.length) {
          const links: HTMLAnchorElement[] = [];
          s3Attachment.forEach(async (attach) => {
            const link = document.createElement("a");
            link.href = attach.s3SignedUrl;
            link.download = attach.fileName;
            link.style.display = "none";
            links.push(link);
            await new Promise((resolve) => setTimeout(resolve, 500));
          });

          let interval: ReturnType<typeof setInterval> = null;
          const download = (urls: HTMLAnchorElement[]) => {
            const url = urls.pop();
            document.body.appendChild(url);
            url.click();
            document.body.removeChild(url);
            if (urls.length === 0) {
              clearInterval(interval);
            }
          };
          interval = setInterval(download, 1000, links);
        }
      },
    });
  };

  /**
   * @deprecated
   * method deprecated and superseded by method UpdateTallyClosingBalance
   */
  const updateClosingBalanceByClosingBalanceDate = async (
    row: Row<BusinessPartnersListForBalanceConfirmation>,
    value: string
  ) => {
    await useFetch("/api/UpdateClosingBalanceByClosingBalanceDate", "PUT", {
      data: {
        businessPartnerId: row.original.businessPartnerId,
        closingBalanceDate: value,
        companyId: companyId.current,
      },
      thenCallBack: (response) => {
        storeClosingBalanceValueFlag.current = false;
        storeClosingBalanceDateFlag.current = false;

        updateClosingBalanceByClosingBalanceDateVal(response?.data?.businessPartnerUpdatedDetails, value);

        if (response.data.message) {
          toast.warning(<CustomToast message={response?.data?.message} />);
        }
      },
    });
  };

  const updateClosingBalanceByClosingBalanceDateVal = (obj: any, value: any) => {
    storeBalanceConfirmationData.current?.map((item: any) => {
      if (item.businessPartnerId === obj?.business_partner_id) {
        item.closingBalance = obj?.closing_balance;
        item.dateOfClosingBalance = value;
      }
      return item;
    });
    // setRowsDataBalanceConfirmation(tempRowsData);
    // setStoreRowsDataBalanceConfirmation(tempRowsData);
  };

  const resetStatesForDemoSales = async () => {
    await useFetch("/api/partnerCommunication/ResetStatesForDemoSales", "POST", {
      thenCallBack: (response) => {
        setShowLoadingIcon(false);
        listAllBusinessPartnerIds();
        // listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh();
        // listBusinessPartnersForBalanceConfirmation();
        setShowResetButton(false);
        if (response.data.message) {
          toast.warning(<CustomToast message={response?.data?.message} />);
        }
      },
      catchCallBack: () => {
        setShowLoadingIcon(false);
      },
    });
  };

  const UpdateBalanceConfirmationStatus = async (row: Row<BusinessPartnersListForBalanceConfirmation>) => {
    setLoadingManualCb(true);
    await useFetch("/api/partnerCommunication/UpdateBalanceConfirmationStatus", "POST", {
      data: {
        businessPartnerId: row.original.businessPartnerId,
        ownCompanyId: lastCompany?.companyId || null,
        businessPartnerCompanyId: null,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: () => {
        setLoadingManualCb(false);
        setOpen(false);
        row.original.balanceConfirmationStatus = "Balance confirmed";
        setRowsDataBalanceConfirmation([...rowsDataBalanceConfirmation]);
        // listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh();
      },
      catchCallBack: () => {
        setLoadingManualCb(false);
      },
    });
  };

  const RevertBalanceConfirmedStatus = async (row: Row<BusinessPartnersListForBalanceConfirmation>) => {
    setLoadingManualCb(true);
    await useFetch("/api/partnerCommunication/ManuallyRevertBalanceConfirmedStatus", "POST", {
      data: {
        businessPartnerId: row.original.businessPartnerId,
        ownCompanyId: lastCompany?.companyId || null,
        businessPartnerCompanyId: null,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: () => {
        setLoadingManualCb(false);
        setOpen(false);
        listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh();
      },
      catchCallBack: () => {
        setLoadingManualCb(false);
      },
    });
  };

  useEffect(() => {
    // add event listener to the document object
    document.addEventListener("click", handleClickOutside);

    return () => {
      // remove event listener when component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    // check if click target is outside of the button
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setShowResetButton(false);
    }
  };

  const companyNameSelect = (e: any, option: ListOwnTallyCompanies | CompanyInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      companyId.current = option.companyId;
      setLastCompany(option);
      if (!actor.branchLevelReconcilation) {
        listAllBusinessPartnerIds(); // after this call, rows api auto called
        listUserEmailTemplateForBalanceConfirmation("");
        getListUserEmailSetting();
        getBulkMailStatus();
      }
    } else if (option === null) {
      companyId.current = null;
    }
  };

  const AfterListCompanies = (_res: ListOwnTallyCompaniesRes | ListCompaniesRes) => {
    if (!actor.branchLevelReconcilation) CheckDateSameWithLastStatusDate();
  };

  const branchNameSelect = (e: any, option: BranchInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      branchCode.current = option.branchCode;
      setLastBranch(option);
      if (actor.branchLevelReconcilation) {
        listAllBusinessPartnerIds(); // after this call, rows api auto called
        listUserEmailTemplateForBalanceConfirmation("");
        getListUserEmailSetting();
        getBulkMailStatus();
      }
    } else if (option === null) {
      ResetOnCompanyChange();
    }
  };

  const ResetOnCompanyChange = () => {
    branchCode.current = null;
    setLastBranch(null);
    setRowsDataBalanceConfirmation([]);
    setStoreRowsDataBalanceConfirmation([]);
  };

  const AfterListBranches = (_res: ListBranchesRes) => {
    if (!actor.branchLevelReconcilation) CheckDateSameWithLastStatusDate();
  };

  const getDownloadBalanceConfirmationReport = () => {
    useFetch("/api/partnerCommunication/DownloadBalanceConfirmationReport", "GET", {
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        setDownloadBalanceConfirmationReportBase64(response.data?.reportWorkbookBase64);
        downloadBalanceConfirmationReport(response.data?.reportWorkbookBase64);
      },
    });
  };
  const downloadBalanceConfirmationReport = (reportBase64) => {
    const excelData = Buffer.from(reportBase64 || downloadBalanceConfirmationReportBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, `${actor.name} Balance Confirmation.xlsx`);
  };

  const deleteUserEmailTemplate = (templateId) => {
    setShowDeleteLoadingIcon(true);
    useFetch("/api/partnerCommunication/DeleteUserEmailTemplate", "DELETE", {
      config: {
        data: {
          useCaseType: UseCaseType.balanceConfirmation,
          templateId,
        },
      },
      thenCallBack: () => {
        listUserEmailTemplateForBalanceConfirmation("");
        handleCloseDeleteModal();
        setShowDeleteLoadingIcon(false);
      },
      catchCallBack: () => {
        setShowDeleteLoadingIcon(false);
      },
    });
  };

  const listFrequencyTable = () => {
    useFetch("/api/partnerCommunication/ListFrequencyTable", "GET", {
      thenCallBack: (response) => {
        const frequencyArray = response.data?.frequencyTableSettingsFilteredByCategories?.map(
          (item: { frequency: string }) => item.frequency
        );
        setFrequencyTableSettings(response.data?.frequencyTableSettingsFilteredByCategories);
        setOptionsBalanceConfirmationFrequency(frequencyArray);
      },
    });
  };

  useEffect(() => {
    if (rowsDataBalanceConfirmation && defaultTallyCbDate) GetErpClosingBalanceForAllBp(rowsDataBalanceConfirmation);

    // eslint-disable-next-line
  }, [statusDataReady, defaultTallyCbDate]);

  useEffect(() => {
    if (updatingTallyCbDate) {
      const intervalsInMs = [10_000, 20_000, 30_000];

      intervalsInMs.forEach((interval) => {
        const intervalId = setInterval(() => {
          try {
            PollErpClosingBalanceStatus();
          } finally {
            if (interval !== 30_000) clearInterval(intervalId);
          }
        }, interval);
        lastTallyInterval.current = intervalId;
      });
    } else {
      window.clearInterval(lastTallyInterval.current);
    }

    return () => window.clearInterval(lastTallyInterval.current);
    // eslint-disable-next-line
  }, [updatingTallyCbDate]);

  const PollErpClosingBalanceStatus = async () => {
    interface Response {
      isClosingBalanceUpdating: boolean;
      error: string;
      closingBalanceDate: string;
    }

    useFetch<Response>("/api/GetERPClosingBalanceStatus", "GET", {
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (res) => {
        const { isClosingBalanceUpdating, closingBalanceDate } = res.data;
        let TallyCbDate = "";
        if (!isClosingBalanceUpdating) {
          if (closingBalanceDate) TallyCbDate = moment(closingBalanceDate).format("YYYY-MM-DD");
          else TallyCbDate = moment().format("YYYY-MM-DD");

          setDefaultTallyCbDate(TallyCbDate);
          setUpdatingTallyCbDate(false);

          // if (closingBalanceDate) setBulkTallyCbDate(TallyCbDate);
        } else if (isClosingBalanceUpdating) {
          setUpdatingTallyCbDate(true);
          if (closingBalanceDate && !bulkTallyCbDate)
            setBulkTallyCbDate(moment(closingBalanceDate).format("YYYY-MM-DD"));
          else if (!closingBalanceDate) setDefaultTallyCbDate(moment().format("YYYY-MM-DD"));
        }
      },
    });
  };

  const GetErpClosingBalanceForAllBp = (rowsBalanceConfirmation: BusinessPartnersListForBalanceConfirmation[]) => {
    useFetch<TallyClosingBalanceForAllBpResponse>("/api/GetERPClosingBalanceForAllBp", "GET", {
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          closingBalanceDate: moment(defaultTallyCbDate).format("YYYY-MM-DD"),
          service: "PartnerCommunication",
        },
      },
      thenCallBack: (res) => {
        const { closingBalanceOfAllBp } = res.data;
        if (closingBalanceOfAllBp?.length > 0) {
          rowsBalanceConfirmation.forEach((row) => {
            const closingBalObj = closingBalanceOfAllBp.find((cb) => cb.businessPartnerId === row.businessPartnerId);
            if (closingBalObj) {
              row.closingBalance = closingBalObj.closingBalance;
              row.dateOfClosingBalance = closingBalObj.closingBalanceDate;
            }
          });
          setRowsDataBalanceConfirmation([...rowsBalanceConfirmation]);

          // find highest count of balance confirmation date
          // const count: { [k: string]: number } = {};
          const firstRowCBDate = closingBalanceOfAllBp[0].closingBalanceDate;
          const allRowCBDateSame = closingBalanceOfAllBp.every((row) => row.closingBalanceDate === firstRowCBDate);
          // closingBalanceOfAllBp.forEach((e) => {
          //   if (count.hasOwnProperty(e.closingBalanceDate)) count[e.closingBalanceDate] += 1;
          //   else count[e.closingBalanceDate] = 1;
          // });
          // const arr = Object.entries(count)?.sort((a, b) => b[1] - a[1]);
          if (allRowCBDateSame) setBulkTallyCbDate(firstRowCBDate);
          else setBulkTallyCbDate(null);
        }
        setLoadingTallyCbAll(false);
      },
    });
  };

  const UpdateClosingBalanceForAllBpUsingPCDate = async () => {
    setUpdatingTallyCbDate(true);
    useFetch<TallyClosingBalanceForAllBpResponse>("/api/UpdateClosingBalanceForAllBpUsingPCDate", "POST", {
      showSuccessToast: true,
      data: {
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
    });
  };

  const UpdateErpClosingBalanceForAllBp = (date: string) => {
    setUpdatingTallyCbDate(true);
    setLoadingTallyCbAll(true);
    const momentDate = moment(date).isValid() ? moment(date) : moment();
    useFetch<TallyClosingBalanceForAllBpResponse>("/api/UpdateERPClosingBalanceForAllBp", "POST", {
      showSuccessToast: true,
      data: {
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        closingBalanceDate: momentDate.format("YYYY-MM-DD"),
        service: "PartnerCommunication",
      },
    });
  };

  const UpdateErpClosingBalance = (row: Row<BusinessPartnersListForBalanceConfirmation>, date: string) => {
    const Response = {
      businessPartnerId: 0,
      closingBalance: 0,
      closingBalanceDate: "",
      openingBalance: 0,
    };

    useFetch<typeof Response>("/api/UpdateERPClosingBalance", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: row.original.businessPartnerId,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        closingBalanceDate: moment(date).format("YYYY-MM-DD"),
        service: "PartnerCommunication",
      },
      thenCallBack: (res) => {
        if (res.data.businessPartnerId === row.original.businessPartnerId)
          row.original.closingBalance = res.data.closingBalance;
        storeClosingBalanceValueFlag.current = false;
        storeClosingBalanceDateFlag.current = false;
      },
    });
  };

  const viewEmail = (businessPartner: number) => {
    return new Promise<ViewEmailThreadResponse>((resolve, reject) => {
      useFetch<ViewEmailThreadResponse>("/api/partnerCommunication/ViewEmail", "POST", {
        data: {
          businessPartnerId: businessPartner,
          ownCompanyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          businessPartnerCompanyId: null,
          templateType: UseCaseType.balanceConfirmation,
        },
        thenCallBack: (response) => {
          if (response.data.mailThreads)
            resolve({
              mailThreads: response.data.mailThreads,
              s3Attachments: response.data.s3Attachments,
              emailSubject: response.data.emailSubject,
            });
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const getEmailsSentInBulkStatus = () => {
    return new Promise((resolve) => {
      useFetch<BulkMailStatusResponse>("/api/partnerCommunication/GetEmailsSentInBulkStatus", "POST", {
        data: {
          templateType: UseCaseType.balanceConfirmation,
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
        thenCallBack: (response) => {
          if (response.data.isComplete) {
            if (response.data?.emailResponseMessage !== "") {
              listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh();
              toast.success(<CustomToast message={response.data.emailResponseMessage} />);
            }
            // Stop the interval and cleanup
            clearInterval(intervalId);
            intervalId = null;
            dispatch(updateIsSendingBulkMailsBC(false));

            if (response.data.listOfFailedUsers && response.data.listOfFailedUsers?.length) {
              if (openBulkMailReportDialogBC) return;
              dispatch(updateOpenBulkMailReportDialogBC(true));
              dispatch(updateEmailResponseMsgBC(response.data.emailResponseMessage || ""));
              dispatch(updateMailFailedUsersBC(response.data.listOfFailedUsers || []));
              dispatch(updateMailFailedWorkbookBC(response.data?.failedEmailsWorkbook || ""));
              // reload templates after mail sent
              listUserEmailTemplateForBalanceConfirmation("");
            }
          } else {
            dispatch(updateIsSendingBulkMailsBC(true));
          }
          resolve(response.data.isComplete);
        },
        catchCallBack: () => {
          clearInterval(intervalId);
          intervalId = null;
          dispatch(updateIsSendingBulkMailsBC(false));
          resolve(true);
        },
      });
    });
  };

  const setIntervalBC = () => {
    intervalId = setInterval(async () => {
      const isComplete = await getEmailsSentInBulkStatus();
      if (isComplete) {
        // Bulk email operation is complete, stop the interval and cleanup
        clearInterval(intervalId);
      }
    }, 30000);
  };

  const getBulkMailStatus = async () => {
    useFetch("/api/partnerCommunication/IsBulkEmailComplete", "GET", {
      config: {
        params: {
          templateType: UseCaseType.balanceConfirmation,
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (res) => {
        if (res.data.isComplete === false && !intervalId) {
          setIntervalBC();
          dispatch(updateIsSendingBulkMailsBC(true));
        }
      },
    });
  };

  useEffect(() => {
    if (actor.integration === false) getBulkMailStatus();
  }, []);

  const GREEN = "#27B27C";

  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Balance Confirmation", actor.name, location?.state?.openCollapseObj, actor)}
    >
      <BalanceConfirmationContext.Provider value={PartnerCommunicationContextValue}>
        {
          <Grid className="table_list">
            <Grid position="relative">
              <Grid className="vertical_center_align mb_15" sx={{ gap: 2, flexWrap: "wrap" }}>
                {/* Left Side Dropdowns and Status Refresh Buttons */}
                <div className="vertical_center_align" style={{ gap: "16px", flexWrap: "wrap" }}>
                  {!openBalanceConfirmationSettings && (
                    <>
                      {actor.integration === true && (
                        <IntegratedDropDown
                          tallyCompanyNameSelect={companyNameSelect}
                          AfterListOwnTallyCompanies={AfterListCompanies}
                          companyNameSelect={companyNameSelect}
                          AfterListCompanies={AfterListCompanies}
                          branchNameSelect={branchNameSelect}
                          AfterListBranches={AfterListBranches}
                          isDisabled={isSendingBulkMailsBC}
                        />
                      )}
                      <Button
                        variant="contained"
                        className="theme_btn"
                        disabled={statusButtonClicked || isSendingBulkMailsBC}
                        onClick={() => {
                          listBusinessPartnersForBalanceConfirmation();
                          uiLogger(uiLoggerName.ui_RefreshStatus, companyId.current, branchCode.current);
                        }}
                        startIcon={<Sync className={statusDataFetching ? "loading-active" : ""} />}
                      >
                        Refresh status
                      </Button>

                      <div>
                        <div style={{ fontSize: "10px" }}>Last Status Updated at:</div>
                        <Chip label={statusText} size="small" />
                      </div>
                      {actor.integration && (
                        <Button
                          variant="contained"
                          className="theme_btn"
                          disabled={statusButtonClicked || isSendingBulkMailsBC}
                          onClick={() => {
                            UpdateClosingBalanceForAllBpUsingPCDate();
                            uiLogger(uiLoggerName.ui_ClosingBalanceRefresh, companyId.current, branchCode.current);
                          }}
                          startIcon={<Sync className={statusDataFetching ? "loading-active" : ""} />}
                        >
                          Refresh Closing Balance
                        </Button>
                      )}
                      {((actor.id === 2866 && import.meta.env.VITE_APP_NAKAD_ENV === "production") ||
                        (actor.id === 1882 && import.meta.env.VITE_APP_NAKAD_ENV === "staging") ||
                        (actor.id === 11920 && import.meta.env.VITE_APP_NAKAD_ENV === "test")) && (
                        <span
                          ref={buttonRef}
                          onClick={() => {
                            setShowResetButton(true);
                          }}
                          style={{ width: "155px", marginLeft: "60px" }}
                        >
                          {showResetButton && (
                            <Button
                              disabled={showLoadingIcon}
                              variant="contained"
                              className="theme_outline_btn"
                              onClick={() => {
                                setShowLoadingIcon(true);
                                resetStatesForDemoSales();
                              }}
                            >
                              {<LoadingIcon loading={showLoadingIcon} />}
                              Reset States
                            </Button>
                          )}
                        </span>
                      )}
                    </>
                  )}
                </div>

                {/* Settings Back Button */}
                {openBalanceConfirmationSettings && (
                  <Tooltip title="Back" arrow={true}>
                    <Avatar sx={{ background: GREEN, ml: "auto", mr: 5, zIndex: 3 }}>
                      <IconButton onClick={() => setOpenBalanceConfirmationSettings(false)} color="inherit">
                        <span className="fa-icon">
                          <i className="fas fa-arrow-left" />
                        </span>
                      </IconButton>
                    </Avatar>
                  </Tooltip>
                )}

                {/* Right Side Buttons */}
                {!openBalanceConfirmationSettings && (
                  <div className="d_flex" style={{ gap: 16, flexWrap: "wrap", marginLeft: "auto" }}>
                    <Tooltip title="Balance Confirmation Settings" arrow={true}>
                      <Avatar
                        sx={{ background: GREEN }}
                        onClick={() =>
                          uiLogger(uiLoggerName.ui_ClickedOnSetting, companyId.current, branchCode.current)
                        }
                      >
                        <IconButton
                          onClick={() => setOpenBalanceConfirmationSettings(true)}
                          color="inherit"
                          disabled={isSendingBulkMailsBC}
                        >
                          <Settings />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip
                      title={
                        statusDataFetching || updatingTallyCbDate || loadingTallyCbAll
                          ? `Please wait till ${statusDataFetching ? "status" : "closing balance"} gets updated`
                          : "Filter"
                      }
                      arrow={true}
                    >
                      <Avatar
                        sx={{ background: GREEN }}
                        onClick={() => uiLogger(uiLoggerName.ui_ClickedOnFilter, companyId.current, branchCode.current)}
                      >
                        <span style={{ cursor: "pointer" }}>
                          <IconButton
                            onClick={() => setOpenBalanceConfirmationFilter(true)}
                            color="inherit"
                            disabled={!statusDataReady}
                            sx={{
                              pointerEvents: "auto !important",
                            }}
                          >
                            {isFilterAppliedBalanceConfirmation ? <FilterAlt /> : <FilterAltOff />}
                          </IconButton>
                        </span>
                      </Avatar>
                    </Tooltip>
                    <Tooltip
                      title={
                        statusDataFetching || updatingTallyCbDate || loadingTallyCbAll
                          ? ` Please wait till ${statusDataFetching ? "status" : "closing balance"} gets updated`
                          : "Bulk Email"
                      }
                      arrow={true}
                    >
                      <Avatar
                        sx={{ background: GREEN }}
                        onClick={() =>
                          uiLogger(uiLoggerName.ui_clickedOnBulkEmail, companyId.current, branchCode.current)
                        }
                      >
                        <IconButton
                          onClick={async () => {
                            const date = await LastStatusRefreshDate();
                            const dateDiff = DateDifference(moment(), moment(date), "minutes");

                            const _foundImposterRow = selectedRow?.find(
                              (row) =>
                                row.balanceConfirmationStatus !== BalanceConfirmationStatuses.RequestBalanceConfirmation
                            );

                            if (_foundImposterRow) setAllowSubjectChange(false);
                            else setAllowSubjectChange(true);

                            setSendSoleOrBulkMail(EmailSendCount.Bulk);

                            if (Math.abs(dateDiff) > 60) {
                              setOpenStatusRefreshDialog(true);
                              setDifferenceTime(dateDiff);
                            } else {
                              setOpenSelectEmailTemplate(true);
                            }
                          }}
                          disabled={statusDataFetching || (selectedRow.length < 2 ? true : isSendingBulkMailsBC)}
                          color="inherit"
                        >
                          {isSendingBulkMailsBC ? (
                            <span
                              className="file-icon"
                              style={{
                                position: "absolute",
                                transform: "scale(2.5)",
                                left: 8,
                                color: "white",
                              }}
                            >
                              <i className="fas fa-circle-notch fa-spin" />
                            </span>
                          ) : (
                            <></>
                          )}
                          <span className="fa-icon">
                            <i className="fas fa-envelope" />
                          </span>
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Download Balance Confirmation Report" arrow={true}>
                      <Avatar
                        sx={{ background: GREEN }}
                        onClick={() => uiLogger(uiLoggerName.ui_DownloadReport, companyId.current, branchCode.current)}
                      >
                        <IconButton
                          onClick={() => getDownloadBalanceConfirmationReport()}
                          color="inherit"
                          disabled={isSendingBulkMailsBC}
                        >
                          <Download />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                  </div>
                )}
              </Grid>

              {/* Filter Applied Bar */}
              {!openBalanceConfirmationSettings && Object.keys(appliedFilters).length > 0 && (
                <FilterAppliedBar appliedFilters={appliedFilters} />
              )}

              <div
                className="bpDashboard"
                style={{
                  height: "60vh",
                  position: "relative",
                  width: "100%",
                  top: openBalanceConfirmationSettings ? "-50px" : "unset",
                }}
              >
                <Grid
                  sx={{
                    "& > div": {
                      animation: "fade-in 0.25s ease-out",
                    },
                  }}
                >
                  {!openBalanceConfirmationSettings && (
                    <BpDashboardTable
                      columns={columnDefinitionBalanceConfirmation}
                      rows={rowsDataBalanceConfirmation}
                      sortEnable={true}
                      setSelectedRow={setSelectedRow as StateDispatch<any[]>}
                      actorType="BPDashboard"
                      loading={loader || !statusDataReady}
                      truncateHeaders={true}
                    />
                  )}

                  {openBalanceConfirmationSettings && (
                    <BalanceConfirmationSettings
                      frequencyTableSettings={frequencyTableSettings}
                      optionsBalanceConfirmationFrequency={optionsBalanceConfirmationFrequency}
                      companyId={companyId.current}
                      branchCode={branchCode.current}
                      listUserEmailTemplate={listUserEmailTemplateForBalanceConfirmation}
                    />
                  )}
                </Grid>
              </div>
            </Grid>
          </Grid>
        }

        <BalanceConfirmationFilter
          openBalanceConfirmationFilter={openBalanceConfirmationFilter}
          setOpenBalanceConfirmationFilter={setOpenBalanceConfirmationFilter}
          allCategories={storeAllCategories.current}
          rowsDataBalanceConfirmation={rowsDataBalanceConfirmation}
          setRowsDataBalanceConfirmation={setRowsDataBalanceConfirmation}
          storeRowsDataBalanceConfirmation={storeRowsDataBalanceConfirmation}
          setIsFilterAppliedBalanceConfirmation={setIsFilterAppliedBalanceConfirmation}
          reFilter={reFilter}
          setReFilter={setReFilter}
          setAppliedFilters={setAppliedFilters}
          companyId={companyId.current}
          branchCode={branchCode.current}
        />

        {/* Balance confirmation */}
        <SelectEmailTemplate
          deleteUserEmailTemplate={(templateID) => {
            deleteUserEmailTemplate(templateID);
          }}
          sendSoleOrBulkMail={sendSoleOrBulkMail}
          lastSelectedTemplateID={lastSelectedTemplateID}
          companyId={companyId.current}
          branchCode={branchCode.current}
        />
        <SendEmailTemplate
          companyId={companyId.current}
          branchCode={branchCode.current}
          base64Image={file}
          signature={signature}
          sendSoleOrBulkMail={sendSoleOrBulkMail}
          selectedRow={selectedRow}
          listAllBusinessPartnerMapping={listBusinessPartnersForBalanceConfirmation}
          // after sending mail to update date
          listAllBusinessPartnersWSR={listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh}
          // setLoader={setLoader}
          partnerCommunicationSelected={PartnerCommunicationSelected.balanceConfirmation}
          templateType={"CB"}
          listUserEmailTemplate={listUserEmailTemplateForBalanceConfirmation}
          storeAllBusinessPartnerCheck={storeAllBusinessPartnerCheck}
          setIntervalBulkMails={setIntervalBC}
        />
        <CreateEmailTemplate // AddUserEmailTemplate ---- inside this create email temp separate api for ledger and bal
          companyId={companyId.current}
          branchCode={branchCode.current}
          listUserEmailTemplate={listUserEmailTemplateForBalanceConfirmation}
          templateType={"CB"}
        />

        {/* ListBusinessPartnerUsers */}
        <Dialog
          open={openListBusinessPartnerUsers}
          onClose={() => setOpenListBusinessPartnerUsers(true)}
          width70Per={true}
        >
          <ListBusinessPartnerUsers
            setOpenListBusinessPartnerUsers={setOpenListBusinessPartnerUsers}
            storeRowOpenListBusinessPartnerUsers={storeRowOpenListBusinessPartnerUsers}
            companyId={companyId.current}
            branchCode={branchCode.current}
            // listAllBusinessPartnersForPartnerUseCaseMaster={listAllBusinessPartnersForPartnerUseCaseMaster}
            // listAllBusinessPartnersForPartnerUserMaster={listAllBusinessPartnersForPartnerUserMaster}
          />
        </Dialog>

        <MailThreadDialog
          open={openMailThread}
          setOpen={setOpenMailThread}
          mailThreads={mailThreads}
          s3Attachments={s3Attachments}
          businessPartnerName={bpName}
          loading={mailLoading}
          companyId={companyId.current}
          branchCode={branchCode.current}
          useCaseType={UseCaseType.balanceConfirmation}
          emailSubject={mailThreadSubject}
          listAllBusinessPartnersWSR={listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh}
        />

        <Popover
          open={open}
          // onClose={() => setOpen(false)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Grid>
            <Typography fontSize={16} p={1}>
              {reversingConfirmCB ? `Revert Manually Confirmed Balance?` : `Confirm Balance Manually?`}
            </Typography>
            <DialogContent className="center_align" sx={{ gap: 1 }}>
              <Button
                className="theme_btn"
                size="small"
                startIcon={<LoadingIcon loading={loadingManualCb} />}
                onClick={() => {
                  reversingConfirmCB
                    ? RevertBalanceConfirmedStatus(currentRow.current)
                    : UpdateBalanceConfirmationStatus(currentRow.current);
                }}
                disabled={loadingManualCb}
              >
                Yes
              </Button>
              <Button className="theme_outline_btn" size="small" onClick={() => setOpen(false)}>
                No
              </Button>
            </DialogContent>
          </Grid>
        </Popover>

        <StatusRefreshDialog
          open={openStatusRefreshDialog}
          setOpen={setOpenStatusRefreshDialog}
          time={differenceTime}
          refreshFn={async () => {
            await listBusinessPartnersForBalanceConfirmation();
            openSelectMailTempOnFinish.current = true;
          }}
        />
        <BulkMailReportDialog
          open={openBulkMailReportDialogBC}
          setOpen={(value) => {
            dispatch(updateOpenBulkMailReportDialogBC(value));
          }}
          emailResponseMsg={emailResponseMsgBC}
          mailFailedUsers={mailFailedUsersBC}
          workbook={mailFailedWorkbookBC}
        />
      </BalanceConfirmationContext.Provider>
    </LoggedInSkeleton>
  );
};

export default BalanceConfirmation;
