import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import { UpdateEmailTemplateDialog } from "../../Common/CommonDialogs";
import CreateEmailTemplate from "../../Common/CreateEmailTemplate";
import CreatePDFTemplate from "../../Common/CreatePDFTemplate";
import EmailEditorWithTemplate from "../../Common/EmailEditorWithTemplate";
import { CommunicationTypeDetails, EmailTemplateDetailsForCommsType } from "../../Common/PartnerCommsInterfaces";
import { PartnerCommsContext } from "../../Context/PartnerCommunicationContext";
import { NdSecondaryButton } from "../../MsmePartnerPortal/CommonComponents";
import { BcBetaContext } from "../BalanceConfirmationBeta";
import {
  BalanceConfirmationOptions,
  DialogSection,
  DialogSectionTitleBar,
  NotConfirmingBCOptions,
  OptionKeys,
  OptionValues,
  RenderCommunicationOptions,
} from "./CommsTypesCommon";

interface NewCommsTypesProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  communicationTypeId?: number;
  onClose?: () => void;
}

const NewCommunicationType = (props: NewCommsTypesProps) => {
  const [commsTypeName, setCommsTypeName] = useState("");
  const [pdfTemplateId, setPdfTemplateId] = useState<number>(null);
  const [emailTemplateId, setEmailTemplateId] = useState<number>(null);
  const [emailSubject, setEmailSubject] = useState("");
  const [editorContent, setEditorContent] = useState("");

  const [selectedConfirmConfig, setSelectedConfirmConfig] = useState<{ [key in OptionKeys]: OptionValues }>({} as any);
  const [selectedNotConfirmConfig, setSelectedNotConfirmConfig] = useState<{ [key in OptionKeys]: OptionValues }>(
    {} as any
  );
  const [emailTemplateDetails, setEmailTemplateDetails] = useState<EmailTemplateDetailsForCommsType>({} as any);

  const [openEmailTemplate, setOpenEmailTemplate] = useState(false);
  const [openPDFTemplate, setOpenPDFTemplate] = useState(false);
  // update email Template section
  const [openUpdateEmailTemplate, setOpenUpdateEmailTemplate] = useState(false);

  const { emailBusinessPartnerId, emailTemplates, emailTemplateContent } = useContext(PartnerCommsContext);
  const { companyId, listUserEmailTemplates } = useContext(BcBetaContext);
  const [templateError, setTemplateError] = useState(false);

  const emailTemplateName =
    emailTemplates.find((item) => item.templateId === emailTemplateId)?.templateName ||
    emailTemplateContent?.templateName;

  const handleClose = () => {
    // clear states
    setCommsTypeName("");
    setSelectedConfirmConfig({} as any);
    setSelectedNotConfirmConfig({} as any);
    setPdfTemplateId(null);
    setEmailTemplateId(null);
    setEmailTemplateDetails({} as any);

    // close dialog
    props.setOpen(false);
  };

  const GetCommunicationTypeDetail = async (communicationTypeId: number) => {
    return useFetch<{ response: string; data: CommunicationTypeDetails }>(
      "/api/CommunicationType/GetCommunicationTypeDetail",
      "GET",
      {
        config: {
          params: {
            communicationTypeId,
          },
        },
        thenCallBack: (_res) => {
          if (_res.data?.data) {
            setSelectedConfirmConfig({
              pdf: _res.data.data.customisationIfConfirmed.pdf,
              ledger: _res.data.data.customisationIfConfirmed.ledger,
              openItem: _res.data.data.customisationIfConfirmed.openItem,
            });

            setSelectedNotConfirmConfig({
              pdf: _res.data.data.customisationIfNotConfirmed.pdf,
              ledger: _res.data.data.customisationIfNotConfirmed.ledger,
              openItem: _res.data.data.customisationIfNotConfirmed.openItem,
            });

            setCommsTypeName(_res.data.data.name);
            setPdfTemplateId(_res.data.data.pdfTemplateId);
            setEmailTemplateDetails(_res.data.data.emailTemplateDetails);
          }
        },
      }
    );
  };

  const CreateOrUpdateCommunicationType = async () => {
    return useFetch<{ response: string; message: string }>(
      "/api/CommunicationType/CreateOrUpdateCommunicationType",
      "POST",
      {
        showSuccessToast: true,
        data: {
          id: props.communicationTypeId || null,
          name: commsTypeName,
          isBalanceConfirmationEnabled: true,
          isDefaultEmailTemplate: emailTemplates.find((item) => item.templateId === emailTemplateId)?.default,
          customisationIfConfirmed: selectedConfirmConfig,
          customisationIfNotConfirmed: selectedNotConfirmConfig,
          customisationIfLedgerRequest: null,
          emailTemplateId: emailTemplateId,
          pdfTemplateId: pdfTemplateId,
        },
        thenCallBack: (_res) => {
          handleClose();
          if (props.onClose) props.onClose();
        },
      }
    );
  };

  const checkIfUserEmailTemplateHasToUpdate = async () => {
    if (emailSubject.trim() === "") {
      setTemplateError(true);
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateType", UseCaseType.balanceConfirmationBeta);
    if (emailTemplateName) {
      bodyFormData.append("templateName", emailTemplateName);
    }

    return useFetch("/api/partnerCommunication/CheckIfEmailTemplateNeedsToBeAdded", "POST", {
      data: bodyFormData,
      config: {
        headers: { "Content-Type": "multipart/form-data" },
      },
      thenCallBack: (response) => {
        // check response here
        if (response.data.isTemplateUpdated) {
          setOpenUpdateEmailTemplate(true);
        } else {
          // if not updated then action
          CreateOrUpdateCommunicationType();
        }
      },
    });
  };

  const updateUserEmailTemplate = async () => {
    setOpenUpdateEmailTemplate(false);

    if (emailSubject.trim() === "") return;

    const bodyFormData = new FormData();
    bodyFormData.append("receiverId", emailBusinessPartnerId?.toString());
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateName", emailTemplateName);
    bodyFormData.append("templateType", UseCaseType.balanceConfirmationBeta);
    bodyFormData.append("senderCompanyId", companyId);

    return useFetch("/api/partnerCommunication/AddUserEmailTemplate", "POST", {
      showSuccessToast: true,
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      thenCallBack: () => {
        // after update action
        CreateOrUpdateCommunicationType();

        // reload templates after Adding/Updating User Email Template
        listUserEmailTemplates();
      },
    });
  };

  useEffect(() => {
    if (props.open && props.communicationTypeId) GetCommunicationTypeDetail(props.communicationTypeId);
  }, [props.open, props.communicationTypeId]);

  return (
    <Dialog open={props.open} maxWidth="md" fullWidth={true}>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle component={"div"}>
          <Typography variant="h6">Create Communication Type</Typography>
        </DialogTitle>
        <IconButton
          onClick={() => {
            handleClose();
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ display: "flex", flexFlow: "column", p: 0, gap: 0.5 }}>
        <Box sx={{ p: 2, pt: 3 }}>
          <TextField
            fullWidth
            size="small"
            label="Communication Name"
            variant="outlined"
            value={commsTypeName}
            onChange={(e) => setCommsTypeName(e.target.value)}
          />
        </Box>
        <DialogSectionTitleBar title="COMMUNICATION PARTICULARS" />
        <DialogSection>
          <FormControlLabel
            control={<Switch defaultChecked disabled />}
            labelPlacement="start"
            label={
              <Box>
                <Typography variant="subtitle1">Balance Confirmation</Typography>
                <Typography variant="body2" color="textSecondary">
                  Toggle on for Balance Confirmation, Toggle off for Ledger Request
                </Typography>
              </Box>
            }
            sx={{ px: 2, py: 1, width: "100%", ml: 0, justifyContent: "space-between" }}
          />
        </DialogSection>

        <DialogSectionTitleBar title={"INFORMATION YOU WANT FROM PARTNER"} />
        <DialogSection>
          <Box display="flex" alignItems="center" py={1} borderBottom={1} borderColor="transparent">
            <Typography variant="subtitle1" fontWeight="medium">
              Partner Confirms the Balance
            </Typography>
            <Divider sx={{ ml: 2, alignSelf: "center", flexGrow: 1 }} />
          </Box>

          {BalanceConfirmationOptions.map((option) => (
            <RenderCommunicationOptions
              key={option.name}
              {...option}
              value={selectedConfirmConfig[option.key]}
              setValue={(v) => {
                setSelectedConfirmConfig((prev) => ({ ...prev, [option.key]: v }));
              }}
              setOpenPDFTemplate={setOpenPDFTemplate}
              pdfTemplateId={pdfTemplateId}
              setPdfTemplateId={setPdfTemplateId}
            />
          ))}

          <Box display="flex" alignItems="center" py={1} borderBottom={1} borderColor="transparent">
            <Typography variant="subtitle1" fontWeight="medium">
              Partner Does not Confirms the Balance
            </Typography>
            <Divider flexItem sx={{ ml: 2, alignSelf: "center", flexGrow: 1 }} />
          </Box>

          {NotConfirmingBCOptions.map((option) => (
            <RenderCommunicationOptions
              key={option.name}
              {...option}
              value={selectedNotConfirmConfig[option.key]}
              setValue={(v) => {
                setSelectedNotConfirmConfig((prev) => ({ ...prev, [option.key]: v }));
              }}
              setOpenPDFTemplate={setOpenPDFTemplate}
              pdfTemplateId={pdfTemplateId}
              setPdfTemplateId={setPdfTemplateId}
            />
          ))}
        </DialogSection>

        <DialogSectionTitleBar title={"EMAIL AUTOMATION"} />
        {/* Email Editor With Template */}
        <EmailEditorWithTemplate
          inputEditorState={{ emailTemplateId: emailTemplateDetails.id, templateError: templateError }}
          returnEditorState={(sub, content, id) => {
            setEmailSubject(sub);
            setEditorContent(content);
            setEmailTemplateId(id);
          }}
          sectionsCustomizations={{ uploadArea: false }}
          otherProps={{
            companyId: companyId,
            listUserEmailTemplates: listUserEmailTemplates,
            templateType: UseCaseType.balanceConfirmationBeta,
          }}
        />

        <DialogSection className="d_flex" sx={{ flexDirection: "column", px: 2, py: 3, gap: 2 }}></DialogSection>
      </DialogContent>
      <DialogActions sx={{ p: 2, bgcolor: "#f1f1f1" }}>
        <NdSecondaryButton
          variant="contained"
          onClick={() => {
            checkIfUserEmailTemplateHasToUpdate();
          }}
        >
          SAVE
        </NdSecondaryButton>
      </DialogActions>
      <CreateEmailTemplate
        open={openEmailTemplate}
        setOpen={setOpenEmailTemplate}
        listUserEmailTemplates={listUserEmailTemplates}
        templateType={UseCaseType.balanceConfirmationBeta}
      />
      <CreatePDFTemplate open={openPDFTemplate} setOpen={setOpenPDFTemplate} />
      {/* Update Email Template Dialog */}
      <UpdateEmailTemplateDialog
        open={openUpdateEmailTemplate}
        setOpen={setOpenUpdateEmailTemplate}
        yesAction={async () => {
          await updateUserEmailTemplate();
        }}
        noAction={async () => {
          return;
        }}
      />
    </Dialog>
  );
};

export default NewCommunicationType;
