import { AttachFile, Delete, DownloadRounded } from "@mui/icons-material";
import { Box, Button, Chip, Divider, Stack, Typography } from "@mui/material";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import { NdButton } from "../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import ErrorPage from "../PartnerCommunication/MsmePartnerPortal/ErrorPage";
import { Header, LoadingPage, NavBar } from "../PartnerCommunication/MsmePartnerPortal/PartnerPortal.common";
import $ from "../PartnerCommunication/MsmePartnerPortal/PartnerPortal.module.scss";

// Define the type for route parameters
interface RouteParams {
  scriptName: string;
}

const LedgerPreprocessingPortalAction = {
  getSopHtml: "getSopHtml",
  runPythonScript: "runPythonScript",
};

const LedgerPreprocessingPortalJobStatus = {
  True: "True",
  False: "False",
};

const LedgerPreprocessingPortal = () => {
  const [files, setFiles] = useState([]);
  const history = useHistory();

  const fileUploader = useRef<HTMLInputElement>(null);
  const [processLedgerLoader, setProcessLedgerLoader] = useState(false);
  const [pythonScriptName, setPythonScriptName] = useState("");
  const [sopHtml, setSopHtml] = useState("");
  // Use useParams to get the scriptName from the URL
  const { scriptName } = useParams<RouteParams>();
  const [verifiedCompany, setVerifiedCompany] = useState(false);
  const [clientName, setClientName] = useState("");
  const jobIdRef = useRef("");
  const [processedLedgerComplete, setProcessedLedgerComplete] = useState(false);
  const [downloadProcessedLedgerStart, setDownloadProcessedLedgerStart] = useState(false);

  useEffect(() => {
    if (scriptName) {
      setPythonScriptName(scriptName); // Set the state to the script name from the URL
    }
    ledgerPreProcessing(LedgerPreprocessingPortalAction.getSopHtml, scriptName);
  }, []);

  const ledgerPreProcessing = (action, pythonScriptNameVal) => {
    const bodyFormData = new FormData();

    bodyFormData.append("action", action);
    bodyFormData.append("pythonScriptName", pythonScriptNameVal);
    if (action === LedgerPreprocessingPortalAction.runPythonScript) {
      setProcessLedgerLoader(true);

      for (const item of files) {
        bodyFormData.append("file", item);
      }
    } else {
      bodyFormData.append("file", null);
    }

    useFetch("/api/Ledger-Preprocessing/LedgerPreProcessing", "POST", {
      // showSuccessToast: true,
      data: bodyFormData,
      thenCallBack: (_res) => {
        if (_res.data.data.htmlData) {
          setSopHtml(_res.data?.data?.htmlData);
          setClientName(_res.data?.data?.companyName || "");
          setVerifiedCompany(true);
        }
        if (_res.data.data.jobId && action === LedgerPreprocessingPortalAction.runPythonScript) {
          jobIdRef.current = _res.data?.data?.jobId;
          getLedgerPreprocessingJobStatus();
        }
      },
      catchCallBack: (_err) => {
        setProcessLedgerLoader(false);
        if (action === LedgerPreprocessingPortalAction.getSopHtml) {
          setTimeout(() => {
            history.push("/");
          }, 5000); // 5000 milliseconds = 5 seconds
        }
      },
    });
  };

  const getLedgerPreprocessingJobStatus = () => {
    useFetch("/api/Ledger-Preprocessing/GetLedgerPreProcessingJobStatus", "POST", {
      data: {
        jobId: jobIdRef.current,
      },
      thenCallBack: (_res) => {
        if (_res.data?.data?.jobStatus === LedgerPreprocessingPortalJobStatus.True) {
          setFiles([]);
          setProcessLedgerLoader(false);
          setProcessedLedgerComplete(true);
          toast.success(<CustomToast message="Ledger Processed Successfully" />);
        } else if (_res.data?.data?.jobStatus === LedgerPreprocessingPortalJobStatus.False) {
          setTimeout(() => {
            getLedgerPreprocessingJobStatus();
          }, 5000); // 5000 milliseconds = 5 seconds
        }
      },
      catchCallBack: (_err) => {
        setProcessLedgerLoader(false);
      },
    });
  };

  const downloadLedgerProcessedFile = () => {
    setDownloadProcessedLedgerStart(true);
    useFetch("/api/Ledger-Preprocessing/DownloadLedgerProcessedFile", "POST", {
      data: {
        jobId: jobIdRef.current,
        pythonScriptName: pythonScriptName,
      },
      thenCallBack: (_res) => {
        if (_res.data.data.base64ExcelOutput) {
          const excelData = Buffer.from(_res.data?.data?.base64ExcelOutput, "base64");
          const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([excelData], { type: fileType });
          saveAs(blob, `${_res.data?.data?.fileName}`);
        }
        setDownloadProcessedLedgerStart(false);
      },
      catchCallBack: (_err) => {
        setProcessLedgerLoader(false);
        setDownloadProcessedLedgerStart(false);
      },
    });
  };

  return (
    <>
      <ErrorPage>
        <Suspense fallback={<LoadingPage />}>
          {verifiedCompany ? (
            <>
              <NavBar companyName={clientName || ""} companyLogo={""} />
              <Divider className={$.borderColor} />
              <Header headerText="Pre Processing Ledger" />
              <Divider className={$.borderColor} />
              <Box className="main_container">
                <Stack className="fade_in details_box" py={6} gap={5} maxWidth={900} mx={"auto"}>
                  <Box
                    className={$.BR_fix + " card_mail"}
                    sx={{
                      border: "1px solid #D7D7D7",
                    }}
                  >
                    <Stack sx={{ p: 2, gap: 1 }}>
                      <iframe
                        srcDoc={sopHtml}
                        referrerPolicy="no-referrer"
                        style={{
                          display: "block",
                          width: "100%",
                          height: "210px",
                          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                          color: "#000C",
                        }}
                      />
                    </Stack>
                  </Box>
                  <Suspense fallback={<LoadingPage noHeader={true} />}>
                    <Box className={$.uploader_box + " " + ""}>
                      <Box className="vertical_center_align" p={2} gap={1}>
                        <Box className="left" mr={"auto"}>
                          <Typography variant="subtitle1" fontWeight={700} color={"#000"}>
                            Upload Raw Ledger
                          </Typography>
                          <Typography variant="body2">Upload the Full Ledger in excel(.xlsx, .csv) format</Typography>
                        </Box>

                        <Button
                          disabled={processLedgerLoader}
                          startIcon={<i className="fa-solid fa-arrow-up-from-bracket"></i>}
                          onClick={() => {
                            fileUploader.current && fileUploader.current.click();
                          }}
                          sx={{ minWidth: 95 }}
                        >
                          Upload
                        </Button>
                      </Box>
                      {files.length > 0 &&
                        files.map((file, index) => (
                          <Box
                            key={index}
                            className="d_flex"
                            sx={{
                              borderTop: "1px solid #D7D7D7",
                              ":last-child": { borderRadius: "0 0 4px 4px" },
                            }}
                            p={2}
                            gap={1}
                          >
                            <Chip
                              sx={{ mr: "auto", borderRadius: "8px", maxWidth: "85%" }}
                              variant="outlined"
                              label={
                                <div className="vertical_center_align">
                                  <AttachFile fontSize="small" />
                                  <Typography
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                    title={file.name}
                                  >
                                    {file.name}
                                  </Typography>
                                </div>
                              }
                            />

                            <Button
                              className={$.BR_fix}
                              variant="outlined"
                              startIcon={<Delete />}
                              disabled={processLedgerLoader}
                              color="error"
                              onClick={() => {
                                setFiles(files.filter((f) => f.name !== file.name));
                              }}
                            >
                              Delete
                            </Button>
                          </Box>
                        ))}
                      <Box
                        className="right_align"
                        sx={{
                          borderTop: "1px solid #D7D7D7",
                          background: "#F3F3F3",
                          ":last-child": { borderRadius: "0 0 4px 4px" },
                        }}
                        p={2}
                      >
                        <NdButton
                          className={$.BR_fix}
                          variant="contained"
                          startIcon={<LoadingIcon loading={processLedgerLoader} />}
                          disabled={files.length < 1 || processLedgerLoader}
                          onClick={() => {
                            ledgerPreProcessing(LedgerPreprocessingPortalAction.runPythonScript, pythonScriptName);
                          }}
                        >
                          Process Ledger
                        </NdButton>
                      </Box>
                    </Box>
                    {processedLedgerComplete && (
                      <Box
                        className="d_flex"
                        sx={{ border: "1px solid #2E7D32", background: "#EDF7ED", borderRadius: " 4px" }}
                        p={2}
                        gap={1}
                      >
                        <Box className="vertical_center_align" sx={{ mr: "auto" }}>
                          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                            Ledger Processed
                          </Typography>
                        </Box>

                        <Button
                          className={$.BR_fix}
                          variant="contained"
                          startIcon={
                            downloadProcessedLedgerStart ? (
                              <LoadingIcon loading={downloadProcessedLedgerStart} />
                            ) : (
                              <DownloadRounded />
                            )
                          }
                          disabled={downloadProcessedLedgerStart}
                          color="success"
                          onClick={() => {
                            downloadLedgerProcessedFile();
                          }}
                        >
                          Download
                        </Button>
                      </Box>
                    )}

                    <input
                      ref={fileUploader}
                      name="ConfirmDocUploader"
                      type="file"
                      multiple={false}
                      onClick={(_e) => ((_e.target as HTMLInputElement).value = null)}
                      onChange={(_e) => {
                        const _files = Array.from(_e.target.files || []);
                        if (_files.length > 0) {
                          setFiles(_files);
                          setProcessedLedgerComplete(false);
                        }
                      }}
                      hidden
                      style={{ visibility: "hidden" }}
                      accept=".xlsx, .csv"
                    />
                  </Suspense>
                </Stack>
              </Box>
            </>
          ) : (
            <LoadingPage />
          )}
        </Suspense>
      </ErrorPage>
    </>
  );
};

export default LedgerPreprocessingPortal;
