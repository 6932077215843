import { Clear } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  Divider,
  IconButton,
  InputAdornment,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import { isOverFlowing } from "src/Utils/Common/CheckTextOverflow";
import { uiLoggerNamesWorkflow } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { TICKET_PRIORITIES } from "./CreateTicket";

export default function EditTaskModal({
  type,
  open,
  setOpen,
  row,
  allUsers,
  isEditing,
  setIsEditing,
  fetchRowsData,
  companyId,
  branchCode,
}) {
  const [dueDateErrorText, setDueDateErrorText] = useState("");

  const defaultMaker = allUsers.filter((user) => user.id === row.makerId) || [];
  const defaultChecker = allUsers.filter((user) => user.id === row.checkerId) || [];
  const defaultApprover = allUsers.filter((user) => user.id === row.approverId) || [];

  const [newMaker, setNewMaker] = useState(defaultMaker.length > 0 ? defaultMaker[0] : null);
  const [newChecker, setNewChecker] = useState(defaultChecker.length > 0 ? defaultChecker[0] : null);
  const [newApprover, setNewApprover] = useState(defaultApprover.length > 0 ? defaultApprover[0] : null);
  const [newDueDate, setNewDueDate] = useState(row.dueDate ? new Date(row.dueDate) : null);
  const [newPriority, setNewPriority] = useState(row.priority);
  const [remarks, setRemarks] = useState(row.remarks);

  const updateTaskDetails = () => {
    const payloadObj = { ticketId: row.id || row.ticketId };

    let isAnyFieldUpdated = false;
    if (newMaker && newMaker.id !== row.makerId) {
      isAnyFieldUpdated = true;
      Object.assign(payloadObj, { makerId: newMaker.id });
    }
    if (newChecker && newChecker.id !== row.checkerId) {
      isAnyFieldUpdated = true;
      Object.assign(payloadObj, { checkerId: newChecker.id });
    }
    if (newApprover && newApprover.id !== row.approverId) {
      isAnyFieldUpdated = true;
      Object.assign(payloadObj, { approverId: newApprover.id });
    }
    if (newPriority !== row.priority) {
      isAnyFieldUpdated = true;
      Object.assign(payloadObj, { priority: newPriority });
    }
    const oldDueDate = row.dueDate ? new Date(row.dueDate) : null;

    if (oldDueDate?.getTime() !== newDueDate?.getTime()) {
      isAnyFieldUpdated = true;
      Object.assign(payloadObj, { dueDate: newDueDate });
    }
    let newRemarks = remarks?.trim();
    if (newRemarks === "") newRemarks = null;
    if (newRemarks !== row.remarks) {
      isAnyFieldUpdated = true;
      Object.assign(payloadObj, { remarks: newRemarks });
    }
    if (!isAnyFieldUpdated) {
      setOpen(false);
      return;
    }
    if (type === "workflow") {
      uiLogger(uiLoggerNamesWorkflow.UI_WF_DB_ACTIONS_EDIT_SAVE_CLICK.functionName, companyId, branchCode, {
        message: uiLoggerNamesWorkflow.UI_WF_DB_ACTIONS_EDIT_SAVE_CLICK.message,
        idOfTicket: row.id,
      });
    }
    setIsEditing(true);

    useFetch("/api/tickets/UpdateTicket", "POST", {
      data: payloadObj,
      thenCallBack: () => {
        setIsEditing(false);
        setOpen(false);
        fetchRowsData();
        toast.success(<CustomToast message="Task updated successfully" />);
      },
      catchCallBack: () => {
        setIsEditing(false);
        setOpen(false);
        fetchRowsData();
        toast.success(<CustomToast message="Error in updating the task" />);
      },
    });
  };
  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", width: "500px" } }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
        <Typography
          ref={(th) => {
            if (th && isOverFlowing(th)) th.title = th.innerText;
          }}
          className="textOverflow_hidden"
          variant="h6"
        >
          Edit task for {row.businessPartnerName || row.businessPartner}
        </Typography>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 2 }}>
        <Autocomplete
          onChange={(_, newValue) => {
            setNewMaker(newValue);
          }}
          value={newMaker}
          disableClearable
          options={allUsers}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              <ListItemText>{item.name}</ListItemText>
            </li>
          )}
          getOptionLabel={(option: { name: string; id: number }) => option.name}
          renderInput={(params) => <TextField {...params} label="Maker" variant="standard" />}
        />
        <Autocomplete
          onChange={(_, newValue) => {
            setNewChecker(newValue);
          }}
          value={newChecker}
          disableClearable
          getOptionLabel={(option: { name: string; id: number }) => option.name}
          options={allUsers}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              <ListItemText>{item.name}</ListItemText>
            </li>
          )}
          renderInput={(params) => <TextField {...params} label="Checker" variant="standard" />}
        />
        <Autocomplete
          onChange={(_, newValue) => {
            setNewApprover(newValue);
          }}
          disableClearable
          value={newApprover}
          getOptionLabel={(option: { name: string; id: number }) => option.name}
          options={allUsers}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              <ListItemText>{item.name}</ListItemText>
            </li>
          )}
          renderInput={(params) => <TextField {...params} label="Approver" variant="standard" />}
        />
        <Autocomplete
          onChange={(_, newValue) => {
            setNewPriority(newValue);
          }}
          disableClearable
          value={newPriority}
          options={TICKET_PRIORITIES}
          renderInput={(params) => <TextField {...params} label="Priority" variant="standard" />}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Due Date"
            value={newDueDate}
            onError={(error) => {
              if (error) setDueDateErrorText(error);
              else setDueDateErrorText("");
            }}
            onChange={(date: Date) => {
              setNewDueDate(date);
            }}
            format="dd/MM/yyyy"
            slotProps={{
              textField: {
                helperText: dueDateErrorText && "Invalid Date",
                variant: "standard",
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start" sx={{ margin: 0 }}>
                      {newDueDate && (
                        <IconButton
                          size="small"
                          sx={{ position: "absolute", right: "30px" }}
                          onClick={() => {
                            setNewDueDate(null);
                          }}
                        >
                          <Clear />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                },
              },
            }}
          />
        </LocalizationProvider>
        <TextField
          label="Remarks"
          onChange={(event) => {
            setRemarks(event.target.value);
          }}
          value={remarks}
          multiline
          maxRows={4}
          variant="standard"
        />
      </Box>

      <Divider />
      <DialogActions sx={{ padding: 2, background: "#F1F1F1" }}>
        <Button
          disabled={isEditing}
          variant="contained"
          sx={{
            background: "#27B27C",
            padding: "8px 22px",
            color: "#FFF",
            borderRadius: "4px !important",
            fontWeight: 500,
            fontSize: "15px",
            "&:hover, &.Mui-focusVisible": { background: "#27B27C" },
          }}
          onClick={() => {
            updateTaskDetails();
          }}
        >
          <LoadingIcon loading={isEditing} />
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
