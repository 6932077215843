import { Close } from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import { PartnerCommsContext } from "../Context/PartnerCommunicationContext";
import Editor from "../Editor/Editor";
import { NdButton } from "../MsmePartnerPortal/CommonComponents";

interface CreateEmailTemplateProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  listUserEmailTemplates: (templateName?: string) => void;
  templateType: UseCaseType;
}

const CreateEmailTemplate = ({ open, setOpen, listUserEmailTemplates, templateType }: CreateEmailTemplateProps) => {
  const [emailTemplateName, setEmailTemplateName] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [copyAlert, setCopyAlert] = useState<boolean>(false);

  const { emailTemplates, dynamicContentOptions } = useContext(PartnerCommsContext);

  const handleClose = () => {
    setOpen(false);
    clearAllState();
    listUserEmailTemplates();
  };

  const clearAllState = () => {
    setEmailTemplateName("");
    setEmailSubject("");
    setEditorContent("");
  };

  const saveCreateEmailTemplate = () => {
    if (emailTemplateName.trim() !== "" && emailSubject.trim() !== "") {
      useFetch("/api/partnerCommunication/AddUserEmailTemplate", "POST", {
        showSuccessToast: true,
        data: {
          templateName: emailTemplateName,
          emailBody: editorContent,
          emailSubject,
          templateType: templateType,
        },
        thenCallBack: () => {
          handleClose();
          clearAllState();
        },
      });
    }
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle component={"div"}>
          <Typography variant="h6">Create Email Template</Typography>
        </DialogTitle>
        <IconButton
          onClick={() => {
            handleClose();
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Stack sx={{ gap: 2 }}>
          <TextField
            select
            variant="outlined"
            label="Select Template"
            size="small"
            sx={{ width: 300 }}
            onChange={async (e) => {
              const filteredSelectedTemplate = emailTemplates.filter((item) => {
                return item.templateName === e.target.value;
              });

              if (filteredSelectedTemplate.length === 0) {
                setEmailTemplateName("");
                setEmailSubject("");
                setEditorContent("");
                return;
              }

              const { emailBody, emailSubject, templateName } = filteredSelectedTemplate[0];

              setEmailTemplateName(templateName);
              setEmailSubject(emailSubject);
              setEditorContent(emailBody);
            }}
          >
            {emailTemplates.length > 0 &&
              emailTemplates.map((item, itemInd: number) => (
                <MenuItem key={itemInd} value={item.templateName}>
                  {item.templateName}
                </MenuItem>
              ))}
            <MenuItem value="">Select Template</MenuItem>
          </TextField>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Email Template Name"
            size="small"
            value={emailTemplateName}
            onChange={(e) => {
              setEmailTemplateName(e.target.value);
            }}
          />
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Subject"
            size="small"
            value={emailSubject}
            onChange={(e) => {
              setEmailSubject(e.target.value);
            }}
          />

          <div className="EditorBox">
            <Editor
              dynamicContent={editorContent}
              setDynamicContent={setEditorContent}
              selectedTemplateName={""}
              selectedEmailTemplateContent={null}
            />

            {/* <NewEditor
              content={selectedEmailTemplateContentForCreateEmailTemplate?.emailBody}
              setContent={setDynamicContent}
            /> */}

            <Box className="d_flex">
              <div style={{ height: 40 }} className="mb_10 flex_end">
                {copyAlert && (
                  <Alert severity="success" sx={{ width: 200, height: 48, position: "relative", top: -10 }}>
                    Copied!
                  </Alert>
                )}
              </div>
            </Box>

            <Box className="d_flex" gap={2}>
              {/* Dynamic Content */}
              <Autocomplete
                size="small"
                onChange={(_, value: any) => {
                  if (value !== null) {
                    navigator.clipboard.writeText(value);
                    // insertDynamiccontent(value);
                    setCopyAlert(true);
                    setTimeout(() => {
                      setCopyAlert(false);
                    }, 3000);
                  }
                }}
                sx={{ width: 200 }}
                options={dynamicContentOptions}
                //   value={dynamicContent}
                renderInput={(params) => (
                  <TextField {...params} label="Dynamic Content" placeholder="Dynamic Content" />
                )}
              />
            </Box>
          </div>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2, backgroundColor: "#f1f1f1" }}>
        <NdButton
          variant="contained"
          disabled={!emailTemplateName || !emailSubject}
          size="small"
          onClick={saveCreateEmailTemplate}
        >
          SAVE
        </NdButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEmailTemplate;
